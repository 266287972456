import React from 'react'

const Text = () => {
  return (
    <div className='px-40 py-8 flex flex-col text-white mt-20'>
        <span className='text-7xl font-bold'>ROUVAFE digital</span>
        <span className='text-7xl font-bold'>marketing services</span>
        <div className='flex mt-6 gap-4 items-center'>
            <div className='w-2 h-8 bg-[#a01e4a] border-none rounded-none'></div>
            <div className='flex flex-col'>
                <span className='text-sm'>At Rouvafe Digital, we create and execute Digital Campaigns, content and designs</span>
                <span className='text-sm'>that endears your brand to the right audience while converting them to paying customers.</span>
            </div>
        </div>
       <button className='flex items-center justify-center px-4 py-2 bg-[#a01e4a] w-40 mt-6 mb-16'>Read more</button>
    </div>
  )
}

export default Text;