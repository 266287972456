import React from 'react'
import Header from './Header';
import Text from './Text';

const Home = () => {
  return (
    <div className="relative bg-[url('../assets/bg.png')] bg-blend-lighten">
        <Header />
        <Text />
    </div>
  )
}

export default Home;