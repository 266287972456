import React from 'react'

const Solution = () => {
  return (
    <div className='flex flex-col px-40 py-8 bg-gray-300'>
        <div className='justify-center items-center flex flex-col mt-8'>
            <div className='flex gap-2 items-center'>
                <span className='w-2 h-6 bg-[#a01e4a] border-none rounded-none'></span>
                <span className='font-bold text-2xl text-zinc-800'>Rouvafe solutions</span>
            </div>
            <span className='mt-4 text-sm font-normal text-gray-500'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className='flex gap-16 justify-center items-center mt-8 mb-12'>
            <div className='flex gap-4'>
                <span className='w-20 h-20 rounded-full border-none bg-white'></span>
                <div className='flex flex-col w-60'>
                    <div className='flex gap-2 items-center'>
                        <span className='w-2 h-4 bg-[#a01e4a] border-none rounded-none'></span>
                        <span className='font-bold text-lg text-zinc-800'> Client services</span>
                    </div>
                    <span className='mt-2 text-xs'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error nobis facilis ea at harum, iure pariatur?</span>
                </div>
            </div>
            <div className='flex gap-4'>
                <span className='w-20 h-20 rounded-full border-none bg-white'></span>
                <div className='flex flex-col w-60'>
                    <div className='flex gap-2 items-center'>
                        <span className='w-2 h-4 bg-[#a01e4a] border-none rounded-none'></span>
                        <span className='font-bold text-lg text-zinc-800'> Agency services</span>
                    </div>
                        <span className='mt-2 text-xs'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error nobis facilis ea at harum, iure pariatur?</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Solution;