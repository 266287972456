import React from 'react';
import Marquee from 'react-fast-marquee';
import Access from '../assets/access.png'
import Bolt from '../assets/bolt.png'
import Bigi from '../assets/bigi.png'
import Closeup from '../assets/closeup.png'
import Smirnoff from '../assets/smirnoff.png'
import Dstv from '../assets/dstv.png'
import Eu from '../assets/eu.png'
import Fcmb from '../assets/fcmb.png'
import Guiness from '../assets/guiness.png'
import Hp from '../assets/hp.png'
import Polaris from '../assets/polaris.png'
import Rexona from '../assets/rexona.png'
import Sterling from '../assets/sterling.png'
import Vlisco from '../assets/vlisco.png'


const Logo = () => {
  return (
    <div className='flex px-28 py-8 flex-col'>
        <div className='flex gap-2 items-center justify-center mb-12'>
            <span className='w-2 h-6 bg-[#a01e4a] border-none rounded-none'></span>
            <span className='font-bold text-2xl text-zinc-800'>Brands we have worked with</span>
        </div>
       <Marquee speed={40}>
        <div className='flex gap-8'>
            <img src={Access} alt="" />
            <img src={Bolt} alt="" />
            <img src={Bigi} alt="" />
            <img src={Closeup} alt="" />
            <img src={Smirnoff} alt="" />
            <img src={Dstv} alt="" />
            <img src={Eu} alt="" />
            <img src={Fcmb} alt="" />
            <img src={Guiness} alt="" />
            <img src={Hp} alt="" />
            <img src={Polaris} alt="" />
            <img src={Rexona} alt="" />
            <img src={Sterling} alt="" />
            <img src={Vlisco} alt="" className='mr-8'/>

        </div>
       </Marquee>
    </div>
  )
}

export default Logo;