import React from 'react'
import { CgPin, CgMail, CgPhone, CgInstagram, CgFacebook } from 'react-icons/cg';
import { FaLinkedin, FaTwitter } from 'react-icons/fa'
import Logo from '../assets/logo.png';


const Footer = () => {
  return (
    <div className='flex flex-col px-40 py-8 bg-zinc-800'>
       <div className='flex'>
            <div className='flex flex-col gap-3 w-96'>
                <div className='flex gap-2 items-center mb-2'>
                    <span className='w-2 h-4 bg-[#a01e4a] border-none rounded-none'></span>
                    <span className='text-lg font-semibold tracking-widest text-white'>Services</span>
                </div>
                <span className='text-sm font-medium text-[#a01e4a] tracking-widest'>Web design</span>
                <span className='text-sm font-medium text-[#a01e4a] tracking-widest'>Social Media Management</span>
                <span className='text-sm font-medium text-[#a01e4a] tracking-widest'>Corporate Branding</span>
                <span className='text-sm font-medium text-[#a01e4a] tracking-widest'>Digital Marketing</span>
                <span className='text-sm font-medium text-[#a01e4a] tracking-widest'>Motion & Graphic Design</span>
                <span className='text-sm font-medium text-[#a01e4a] tracking-widest'>Influencer Marketing</span>
            </div>
            <div className='flex flex-col gap-3 w-80'>
                <div className='flex gap-2 items-center mb-2'>
                    <span className='w-2 h-4 bg-[#a01e4a] border-none rounded-none'></span>
                    <span className='text-lg font-semibold tracking-widest text-white'>Contact</span>
                </div>
                <span className='text-sm font-medium text-gray-400 tracking-widest'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
                <div className='flex gap-4'>
                    <span className='w-6 h-6 text-[#a01e4a] items-center flex'><CgPin /></span>
                    <span className='text-sm font-medium tracking-widest text-white'>Lagos, Nigeria</span>
                </div>
                <div className='flex gap-4'>
                    <span className='w-6 h-6 text-[#a01e4a] items-center flex'><CgMail /></span>
                    <span className='text-sm font-medium tracking-widest text-white'>info@Rouvafedigital.com</span>
                </div>
                <div className='flex gap-4'>
                    <span className='w-6 h-6 text-[#a01e4a] items-center flex'><CgPhone /></span>
                    <span className='text-sm font-medium tracking-widest text-white'>+(234) 00000000</span>
                </div>    
            </div>
       </div>
       <div className='flex flex-col gap-8 mt-12'>
            <div className='flex items-center justify-center'>
                <div className='w-2 h-12 bg-[#a01e4a] border-none rounded-none'></div>
                <img src={Logo} alt='' className='h-12 w-44'/>
            </div>
            <div className='flex gap-4 items-center justify-center'>
                <span className='rounded-full border-none bg-gray-400 text-zinc-800 p-1'><CgInstagram className='w-6 h-6'/></span>
                <span className='rounded-full border-none bg-gray-400 text-zinc-800 p-1'><CgFacebook className='w-6 h-6'/></span>
                <span className='rounded-full border-none bg-gray-400 text-zinc-800 p-1'><FaTwitter className='w-6 h-6'/></span>
                <span className='rounded-full border-none bg-gray-400 text-zinc-800 p-1'><FaLinkedin className='w-6 h-6'/></span>
            </div>
       </div>
    </div>
  )
}

export default Footer;