import React from 'react';
import {CgMail} from 'react-icons/cg';

const Mission = () => {
  return (
    <div className='flex'>
      <div className='flex basis-1/2 bg-zinc-800 h-80 pl-36 pr-4 text-white'>
       <div className='flex flex-col mt-16 ml-12'>
        <div className='flex gap-2 items-center mb-4'>
          <span className='w-2 h-6 bg-[#a01e4a] border-none rounded-none'></span>
          <span className='font-bold text-3xl'>Our mission is clear</span>
        </div>
        <span className='text-xl font-semibold text-[#a01e4a]'>The highest quality marketing</span>
        <span className='text-xl font-semibold mb-4 text-[#a01e4a]'>for every client, on every project.</span>
        <span className='text-xs mb-4'>Lorem ipsum dolor sit amet consectetur, adipisicing elit.Dolorum expedita ipsum iusto aliquid impedit necessitatibus praesentium explicabo </span>
        <div className='flex gap-3 items-center'>
          <span className='rounded-full border-2 border-white p-1'><CgMail className='w-10 h-10'/></span>
          <span className='text-xs text-[#a01e4a]'>contact us for more info</span>
        </div>
       </div>
      </div>
      <div className='flex basis-1/2'></div>
    </div>
  )
}

export default Mission;