import React from "react";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Logo from "./components/Logo";
import Mission from "./components/Mission";
import Service from "./components/Service";
import Solution from "./components/Solution";


function App() {
  return (
    <div className="flex flex-col opacity-100">
      <Home />
      <Solution/>
      <Mission />
      <Service />
      <Logo />
      <Footer />
    </div>
  );
}

export default App;
