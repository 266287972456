import React from 'react';
import Logo from '../assets/logo.png';


const Header = () => {
  return (
    <div className="relative text-white flex flex-row px-40 py-8">
            <div className='flex flex-1'>
              <div className='flex'>
                <div className='w-2 h-12 bg-[#a01e4a] border-none rounded-none'></div>
                <img src={Logo} alt='' className='h-12 w-44'/>
              </div>
            </div>
             <div className='flex justify-start items-center p-2'>
                <ul className='flex flex-row gap-2'>
                    <li className='font-semibold text-sm uppercase rounded-sm px-3 py-1 hover:bg-[#a01e4a]/50 cursor-pointer'>About</li>
                    <li className='font-semibold text-sm uppercase rounded-sm px-3 py-1 hover:bg-[#a01e4a]/50 cursor-pointer'>mission</li>
                    <li className='font-semibold text-sm uppercase rounded-sm px-3 py-1 hover:bg-[#a01e4a]/50 cursor-pointer'>services</li>
                    <li className='font-semibold text-sm uppercase rounded-sm px-3 py-1 hover:bg-[#a01e4a]/50 cursor-pointer'>contact</li>
                </ul>
             </div>
    </div>
  )
}

export default Header;