import React from 'react';
import {BsLaptop, BsFillCalendarCheckFill, BsFillTagFill, BsBarChartFill, BsFillImageFill} from 'react-icons/bs';
import {FaUsers} from 'react-icons/fa';

const Service = () => {
  return (
    <div className='flex flex-col px-28 py-8 bg-gray-300'>
        <div className='justify-center items-center flex flex-col my-8 px-12'>
            <div className='flex gap-2 items-center'>
                <span className='w-2 h-6 bg-[#a01e4a] border-none rounded-none'></span>
                <span className='font-bold text-2xl text-zinc-800'>What we do</span>
            </div>
            <span className='mt-4 text-sm font-normal text-gray-500 '>We pride ourselves in the timely and efficient delivery of digital services that are directed towards meeting specific client demands. Below are some of the services we offer.</span>
        </div>
        <div className='flex mb-8'>
            <div className='flex items-center justify-center flex-col gap-3 basis-1/3'>
                <span className='w-20 h-20 rounded-full border-none bg-white p-3'><BsLaptop className='w-14 h-14 text-[#a01e4a]'/></span>
                <span className='text-2xl font-bold text-zinc-800'>Web Design</span>
                <span className='text-xs p-4 text-gray-500 '>
                A website is like your office space online. We understand the need for it to be very functional, responsive and aesthetically pleasing, hence we have a team of experienced web developers who would carry you along every step.
                </span>
            </div>
            <div className='flex items-center justify-center flex-col gap-3 basis-1/3'>
                <span className='w-20 h-20 rounded-full border-none bg-white p-3'><BsFillCalendarCheckFill className='w-14 h-14 text-[#a01e4a]'/></span>
                <span className='text-2xl font-bold text-zinc-800'>Social Media Management</span>
                <span className='text-xs p-4 text-gray-500 '>
                Handling Social Media Platforms for brands/high profile personalities is our forté. Let us take the burden of handling your social media presence off your shoulders, so you can focus on the core of your business.
                </span>
            </div>
            <div className='flex items-center justify-center flex-col gap-3 basis-1/3'>
                <span className='w-20 h-20 rounded-full border-none bg-white p-3'><BsFillTagFill className='w-14 h-14 text-[#a01e4a]'/></span>
                <span className='text-2xl font-bold text-zinc-800'>Corporate Branding</span>
                <span className='text-xs p-4 text-gray-500 '>
                At Rouvafe Digital, we understand the importance of creating recognizable brands that standout anywhere. We are experts at transforming corporate organizations into popular brand names.
                </span>
            </div>
        </div>
        <div className='flex mb-8'>
            <div className='flex items-center justify-center flex-col gap-3 basis-1/3'>
                <span className='w-20 h-20 rounded-full border-none bg-white p-3'><BsBarChartFill className='w-14 h-14 text-[#a01e4a]'/></span>
                <span className='text-2xl font-bold text-zinc-800'>Digital Marketing</span>
                <span className='text-xs p-4 text-gray-500'>
                The internet is an important medium for the most reach your target audience in an efficient manner. At Rouvafe Digital we make use of the latest digital marketing tools & strategies to sell your business, generate hot leads and increase your overall ROI.
                </span>
            </div>
            <div className='flex items-center justify-center flex-col gap-3 basis-1/3'>
                <span className='w-20 h-20 rounded-full border-none bg-white p-3'><BsFillImageFill className='w-14 h-14 text-[#a01e4a]'/></span>
                <span className='text-2xl font-bold text-zinc-800'>Motion & Graphic Design</span>
                <span className='text-xs p-4 text-gray-500'>
                Visually appealing designs and videos are important to tell your brand story and convey important messages wether for advertising purposes or otherwise. We understand this and therefore have a team of creative designers and videographers that will translate your ideas into visually appealing digital assets.
                </span>
            </div>
            <div className='flex items-center justify-center flex-col gap-3 basis-1/3'>
                <span className='w-20 h-20 rounded-full border-none bg-white p-3'><FaUsers className='w-14 h-14 text-[#a01e4a]'/></span>
                <span className='text-2xl font-bold text-zinc-800'>Influencer Marketing</span>
                <span className='text-xs p-4 text-gray-500'>
                Looking for the right influencers to partner with on your next marketing Campaign? We have years of experience in sourcing for and engaging the right influencers across different industries. Give us a call today and we will make your next Influencer marketing campaign a huge success.
                </span>
            </div>
        </div>
    </div>
  )
}

export default Service;